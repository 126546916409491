import React from "react"
import { graphql } from "gatsby"
import LocalizedLink from "../../components/localizedLink"
import useTranslations from "../../components/useTranslations"
import { useTranslation } from "react-i18next"
import SEO from "../../components/seo"

import { MdLooksOne, MdLooksTwo, MdLooks3 } from "react-icons/md"

import bgImage from "../../images/Words_Online_2B.jpg"

const Page = ({ data: { allMdx } }) => {
  const { hello, subline } = useTranslations()
  const { t } = useTranslation()

  return (
    <>
      <SEO
        title="Слова Онлайн - Играйте с друзьями"
        description="Игра в слова, в которой игроки по очереди составляют слова из имеющихся букв и создают кроссворд. Число заработанных очков зависит от длины слова и расположения использованных фишек на игровом поле."
      />

      <section
        className="hero is-medium has-background-dark"
        style={{
          background: `url(${bgImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="hero-body">
          <div className="container has-text-centered">
            <h2 className="subtitle is-size-3 has-text-white">
              Бесплатная игра в составление слов
            </h2>
            <h2 className="subtitle is-size-3 has-text-white">
              Придумывай слова и зарабатывай очки
            </h2>
            <h2 className="subtitle is-size-3 has-text-white">
              Набери наибольшее число очков
            </h2>
            <div
              className="buttons is-centered"
              style={{
                marginTop: "6rem",
              }}
            >
              <a className="button is-primary is-large" disabled href="#">
                Скоро
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="section has-background-dark">
        <div className="container has-text-centered">
          <h2 className="title">Режимы игры</h2>
          <div className="columns">
            <div className="column is-4">
              <MdLooksOne size={64} />
              <h4 className="title is-4">Против "Болвана"</h4>
              <p>
                Компьютер помнит все слова, но плохо знает тактику игры.
                Используй это!
              </p>
            </div>
            <div className="column is-4">
              <MdLooksTwo size={64} />
              <h4 className="title is-4">На двоих с другом</h4>
              <p>Ходы делают по очереди. Нельзя подсматривать чужие фишки!</p>
            </div>
            <div className="column is-4">
              <MdLooks3 size={64} />
              <h4 className="title is-4">Через Интернет</h4>
              <p>
                Играй PvP против случайного оппонента или пригласи друга через
                социальную сеть Facebook и сразись с ним!
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section">
        <div className="container">
          <div className="columns is-centered">
            <div className="column is-8">
              <article className="content">
                <h2 className="title">Полезно знать</h2>
                <div>
                  <div className="media">
                    <div className="media-left">
                      <span className="number">1</span>
                    </div>
                    <div className="media-content">
                      <h3>Играют все слова</h3>
                      <p>
                        Используй любые русские слова. Разрешены все части речи,
                        падежи и множественные числа.
                      </p>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <span className="number">2</span>
                    </div>
                    <div className="media-content">
                      <h3>Игра для вечеринки</h3>
                      <p>
                        Создавай команды и играй на одном компьютере с друзьями.
                        Выясни, кто лучше составляет слова!
                      </p>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <span className="number">3</span>
                    </div>
                    <div className="media-content">
                      <h3>Современный русский словарь</h3>
                      <p>
                        В словаре больше миллиона русских слов. Если какого-то
                        слова не хватает - сообщите нам об этом через форму
                        ниже!
                      </p>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>

      {/* <ul className="post-list">
        {allMdx.edges.map(({ node: post }) => (
          <li key={`${post.frontmatter.title}-${post.fields.locale}`}>
            <LocalizedLink to={`/${post.parent.relativeDirectory}/`}>
              {post.frontmatter.title}
            </LocalizedLink>
            <div>{post.frontmatter.date}</div>
          </li>
        ))}
      </ul> */}
    </>
  )
}

export default Page

export const query = graphql`
  query IndexRu($locale: String!, $dateFormat: String!) {
    allMdx(
      filter: { fields: { locale: { eq: $locale } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: $dateFormat)
          }
          fields {
            locale
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
  }
`
